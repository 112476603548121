import NutritionTemplate from '@/modules/questionnaire/components/steps/questionnaire/oxidative-stress/nutrition/NutritionTemplate';

import { DISCOMFORT, FREQUENCY } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/OxidativeStress/Nutrition/NutritionTemplate'
};

const createStory = props => () => ({
  components: { NutritionTemplate },
  storyProps: props,
  wrapStyles: {
    margin: '20px auto',
    width: '360px',
    maxWidth: '100%'
  },
  template: `<div :style='$options.wrapStyles'>
    <nutrition-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  processedFoodIntake: DISCOMFORT.MEDIUM,
  antiOxidantIntake: FREQUENCY.VERY_OFTEN
});
